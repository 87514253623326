import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as Widget from '../../components/Widget';
import * as Ant from 'antd';

function TabSendOne(props) {
  console.log('render tab send one');
  const app = React.useContext(AppContext.Context);
  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [phone, setPhone] = React.useState('');

  async function send() {
    if (!title || !phone) {
      alert('Must have title and phone');
      return;
    }

    await app.actions.sendSms({
      phone,
      subject: title,
      message,
    });
  }

  return (
    <TabWrapper>
      <div className="field">
        <label>Recipient's Phone Number</label>
        <Ant.Input
          value={phone}
          onChange={(e) => setPhone(e.target.value)}></Ant.Input>
      </div>

      <div className="field">
        <label>Title</label>
        <Ant.Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}></Ant.Input>
      </div>

      <div className="field">
        <label>Message</label>
        <Ant.Input
          value={message}
          onChange={(e) => setMessage(e.target.value)}></Ant.Input>
      </div>

      <Ant.Button type="primary" onClick={send}>
        SEND
      </Ant.Button>
    </TabWrapper>
  );
}

const TabWrapper = styled.div`
  padding-top: 15px;

  & > .field {
    margin-bottom: 10px;

    & label {
      display: block;
    }

    & input {
      max-width: 250px;
    }
  }
`;

export default TabSendOne;
