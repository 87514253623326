import React from 'react';
import * as Ant from 'antd';
import {AddCircle, Refresh} from '@styled-icons/material';
import * as Widget from './Widget';

function ResourceTitleBar(props) {
  const {title, subtitle, onCreateClick, onRefreshClick} = props;
  return (
    <Widget.FlexRow style={{marginBottom: 15, height: 32}}>
      <div style={{flex: 1}}>
        <div style={{fontSize: 18}}>{title}</div>
        {subtitle && (
          <div style={{fontSize: 12, color: '#aaa'}}> {subtitle} </div>
        )}
      </div>
      {onRefreshClick && (
        <Ant.Button
          type="text"
          style={{padding: 0, margin: 0, marginRight: 10}}
          onClick={onRefreshClick}>
          <Refresh size={26} color="#E50914" />
        </Ant.Button>
      )}
      {onCreateClick && (
        <Ant.Button
          type="text"
          style={{padding: 0, margin: 0}}
          onClick={onCreateClick}>
          <AddCircle size={26} color="#E50914" />
        </Ant.Button>
      )}
    </Widget.FlexRow>
  );
}

export default ResourceTitleBar;
