import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as Widget from '../../components/Widget';

function EmailSenderEditor(props) {
  const {isCreating, sender: originalSender, templates, onSave, goBack} = props;
  const [sender, setSender] = React.useState({...originalSender});

  return (
    <Wrapper>
      {isCreating && (
        <Field>
          <label>Name</label>
          <Ant.Input
            placeholder="Unique name to identify this resource"
            value={sender.name || ''}
            onChange={(e) => setSender({...sender, name: e.target.value})}
          />
        </Field>
      )}

      <Field>
        <label>Sender Name</label>
        <Ant.Input
          value={sender.sender_name}
          onChange={(e) => setSender({...sender, sender_name: e.target.value})}
        />
      </Field>

      <Field>
        <label>Sender Email</label>
        <Ant.Input
          value={sender.sender_email}
          onChange={(e) => setSender({...sender, sender_email: e.target.value})}
        />
      </Field>

      <Field>
        <label>Template</label>
        <Ant.Select
          value={sender.template}
          onChange={(value) => setSender({...sender, template: value})}>
          {templates.map((template) => (
            <Ant.Select.Option key={template.name} value={template.name}>
              {template.name}
            </Ant.Select.Option>
          ))}
        </Ant.Select>
      </Field>

      <Widget.FlexRow>
        <Ant.Button type="primary" onClick={() => onSave(sender)}>
          SAVE
        </Ant.Button>

        <Ant.Button style={{marginLeft: 10}} onClick={goBack}>
          CANCEL
        </Ant.Button>
      </Widget.FlexRow>
    </Wrapper>
  );
}

const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 300px;

  & > label {
    display: block;
  }

  margin-bottom: 10px;
`;

const Wrapper = styled.div``;

export default EmailSenderEditor;
