import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as Widget from './Widget';
import {ArrowForward, Delete} from '@styled-icons/material';

function SimpleResourceItem(props) {
  const {resource, idx, children, onClick, onDelete, actions} = props;

  return (
    <ItemWrapper>
      {idx !== undefined && (
        <Widget.FlexCenter
          style={{
            width: 28,
            height: 28,
            color: 'white',
            backgroundColor: '#E50914',
            alignSelf: 'flex-start',
            marginRight: 10,
          }}>
          {idx + 1}
        </Widget.FlexCenter>
      )}

      {resource && (
        <div style={{flex: 1}}>
          <div>{resource.name}</div>
        </div>
      )}

      {children && <div style={{flex: 1}}>{children}</div>}

      {Array.isArray(actions) &&
        actions.map((a, idx) => (
          <Ant.Button type="text" onClick={a.onClick} key={idx}>
            {a.icon}
          </Ant.Button>
        ))}

      {onDelete && (
        <Ant.Button type="text" onClick={onDelete}>
          <Delete size={24} color="black" />
        </Ant.Button>
      )}

      <Ant.Button type="text" onClick={onClick}>
        <ArrowForward size={24} color="black" />
      </Ant.Button>
    </ItemWrapper>
  );
}

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;

  :last-child {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
`;

export default SimpleResourceItem;
