import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as Widget from '../../components/Widget';
import useDimension from '../../hooks/use-dimension';

function EmailTemplateEditor(props) {
  const {isCreating, template, onSave, goBack} = props;
  const [name, setName] = React.useState(template.name || '');
  const [subject, setSubject] = React.useState(template.subject || '');
  const [templateCss, setTemplateCss] = React.useState(template.css || '');
  const [templateHtml, setTemplateHtml] = React.useState(template.html || '');
  const [mobilePreview, setMobilePreview] = React.useState(false);
  const {dimension} = useDimension();

  if (!dimension) {
    return null;
  }

  function saveTemplate() {
    onSave({
      name: isCreating ? name : template.name,
      subject,
      css: templateCss,
      html: templateHtml,
    });
  }

  const mobile = dimension.innerWidth <= 768;

  function renderEditor() {
    return (
      <Widget.FlexCol style={{flex: 1}}>
        {isCreating && (
          <Widget.FlexCol>
            <div>Name</div>
            <input
              style={{padding: '0 10px'}}
              placeholder="Unique name for your resource"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Widget.FlexCol>
        )}

        <Widget.FlexCol>
          <div>Subject</div>
          <input
            style={{padding: '0 10px'}}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Widget.FlexCol>
        <Widget.FlexCol style={{flex: 1}}>
          <div>CSS</div>
          <textarea
            style={{flex: 1, padding: 10}}
            value={templateCss}
            onChange={(e) => setTemplateCss(e.target.value)}
          />
        </Widget.FlexCol>
        <Widget.FlexCol style={{flex: 1}}>
          <div>HTML</div>
          <textarea
            style={{flex: 1, padding: 10}}
            value={templateHtml}
            onChange={(e) => setTemplateHtml(e.target.value)}
          />
        </Widget.FlexCol>
        <Widget.FlexRow style={{justifyContent: 'flex-end', marginTop: 10}}>
          <Ant.Button
            type="primary"
            style={{marginRight: 10}}
            onClick={saveTemplate}>
            SAVE
          </Ant.Button>
          <Ant.Button onClick={goBack}>CANCEL</Ant.Button>
        </Widget.FlexRow>
      </Widget.FlexCol>
    );
  }

  function renderPreview() {
    return (
      <Widget.FlexCol style={{flex: 1, marginRight: mobile ? 0 : 10}}>
        <div>Preview</div>
        <TemplatePreview
          style={{flex: 1, border: '2px dashed grey'}}
          css={templateCss}>
          <div
            className="preview-content"
            dangerouslySetInnerHTML={{__html: templateHtml}}
          />
        </TemplatePreview>
      </Widget.FlexCol>
    );
  }

  if (mobile) {
    return (
      <Wrapper>
        <Ant.Button
          className="toggle-preview"
          onClick={() => setMobilePreview(!mobilePreview)}>
          {`Show ${mobilePreview ? 'Editor' : 'Preview'}`}
        </Ant.Button>
        {mobilePreview && renderPreview()}
        {!mobilePreview && renderEditor()}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {renderPreview()}
      {renderEditor()}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;

  & .toggle-preview {
    position: absolute;
    right: 10px;
    top: 0px;
  }
`;

const TemplatePreview = styled.div`
  height: calc(100% - 30px);
  overflow: auto;

  & > .preview-content {
    ${(props) => props.css}
  }
`;

export default EmailTemplateEditor;
