import React from 'react';
import SlideInPanel from './SlideInPanel';
import styled from 'styled-components';
import useDimension from '../hooks/use-dimension';
import {Close} from '@styled-icons/material';

function BottomPanelContent(props) {
  const {title, content, close, style} = props;

  return (
    <Wrapper onClick={close}>
      <Content
        style={style}
        onClick={(evt) => {
          evt.stopPropagation();
        }}>
        <div className="title-bar">
          <div className="title">{title}</div>
          <Close size={24} onClick={close} />
        </div>
        <div className="scroll-area">{content}</div>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: transparent;
  height: 100%;
  padding-top: 30px;
`;

const Content = styled.div`
  max-width: 600px;
  margin: 10px auto;
  height: 100%;
  background-color: white;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  position: relative;
  & > .title-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: white;
    z-index: 1;
    & > .title {
      flex: 1;
      font-family: VictorMonoItalic;
    }
  }
  & > .scroll-area {
    padding: 40px 20px;
    height: 100%;
    overflow: auto;
  }
`;

function BottomUpSheet(props) {
  const {dimension} = useDimension();
  const {contentStyle = {}} = props;

  return (
    <SlideInPanel
      position="bottom"
      size={dimension?.innerHeight * 0.9 || 500}
      style={{backgroundColor: 'transparent'}}
      getInstance={(inst) => {
        props.getInstance({
          open: async (title, content) => {
            inst.open(
              <BottomPanelContent
                close={inst.close.bind(inst)}
                title={title}
                content={content}
                style={contentStyle}
              />,
            );
          },
          close: () => {
            inst.close();
          },
        });
      }}
    />
  );
}

export default BottomUpSheet;
