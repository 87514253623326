import React from 'react';
import styled from 'styled-components';
import * as Widget from '../components/Widget';

function TabBar(props) {
  const {items, isItemActive, onItemClick} = props;

  return (
    <Widget.FlexRow style={{height: 40}}>
      {items.map((item, idx) => {
        const active = isItemActive(item);
        return (
          <Tab
            title={item}
            key={idx}
            active={active}
            onClick={() => onItemClick(item)}
          />
        );
      })}
    </Widget.FlexRow>
  );
}

function Tab(props) {
  const {title, active, onClick} = props;

  return (
    <TabWrapper active={active} onClick={onClick}>
      {title}
    </TabWrapper>
  );
}

const TabWrapper = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  transition: 150ms;
  color: ${(props) => (props.active ? 'black' : 'gray')};
  border-bottom: 2px solid ${(props) => (props.active ? 'red' : 'transparent')};
  margin-right: 5px;
`;

export default TabBar;
