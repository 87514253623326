import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as Widget from '../../components/Widget';
import * as Ant from 'antd';
import TabBar from '../../components/TabBar';
import TabPush from './TabPush';
import TabSms from './TabSms';
import TabEmail from './TabEmail';

const TabItems = ['EMAIL', 'SMS', 'PUSH'];

function NotificationServicePage(props) {
  const [selectedTab, setSelectedTab] = React.useState(TabItems[0]);

  return (
    <Outer>
      <Wrapper>
        <TabBar
          items={TabItems}
          onItemClick={setSelectedTab}
          isItemActive={(item) => item === selectedTab}
        />
        <div className="tab-content">
          {selectedTab === TabItems[0] && <TabEmail />}
          {selectedTab === TabItems[1] && <TabSms />}
          {selectedTab === TabItems[2] && <TabPush />}
        </div>
      </Wrapper>
    </Outer>
  );
}

const Outer = styled.div`
  padding: 80px 20px 0px 20px;
  background-color: ${AppContext.Theme.lightBgColor};
  height: 100vh;
  position: relative;
`;

const Wrapper = styled.div`
  height: 100%;
  max-width: 1024px;
  margin: 0 auto;

  & > .tab-content {
    height: calc(100% - 40px);
    overflow: auto;
  }
`;

export default NotificationServicePage;
