import {useOutlet} from 'reconnect.js';

function useDimension() {
  const [dimension] = useOutlet('dimension');
  return {
    dimension,
  };
}

export default useDimension;
