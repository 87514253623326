import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as Widget from '../../components/Widget';
import * as Ant from 'antd';

function TabSendAll(props) {
  const app = React.useContext(AppContext.Context);
  const [title, setTitle] = React.useState('');
  const [subtitle, setSubtitle] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [message, setMessage] = React.useState('');

  async function send() {
    if (!title || !message) {
      alert('Must have title and message');
      return;
    }

    await app.actions.sendPushNotification({
      title,
      body: message,
      subtitle,
      data: {
        url,
      },
    });
  }

  return (
    <TabWrapper>
      <div className="field">
        <label>Title</label>
        <Ant.Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}></Ant.Input>
      </div>

      <div className="field">
        <label>Subtitle</label>
        <Ant.Input
          value={subtitle}
          onChange={(e) => setSubtitle(e.target.value)}></Ant.Input>
      </div>

      <div className="field">
        <label>Message</label>
        <Ant.Input
          value={message}
          onChange={(e) => setMessage(e.target.value)}></Ant.Input>
      </div>

      <div className="field">
        <label>URL</label>
        <Ant.Input
          value={url}
          onChange={(e) => setUrl(e.target.value)}></Ant.Input>
      </div>

      <Ant.Button type="primary" onClick={send}>
        SEND
      </Ant.Button>
    </TabWrapper>
  );
}

const TabWrapper = styled.div`
  padding-top: 15px;

  & > .field {
    margin-bottom: 10px;

    & label {
      display: block;
    }

    & input {
      max-width: 250px;
    }
  }
`;

export default TabSendAll;
