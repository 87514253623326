import React from 'react';
import styled from 'styled-components';
import * as Widget from './Widget';

function Breadcrumb(props) {
  const {items, onItemClick = () => 0} = props;

  return (
    <Wrapper>
      {items.map((item, idx) => {
        const isLast = idx + 1 === items.length;
        return (
          <Widget.FlexRow className="item" key={idx}>
            <div
              className="label"
              style={{fontWeight: isLast ? 'bold' : 'normal'}}
              onClick={() => {
                onItemClick(item, idx);
              }}>
              {item}
            </div>

            {!isLast && <div className="slash">{`/`}</div>}
          </Widget.FlexRow>
        );
      })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
  height: 40px;

  & > .item {
    flex-grow: 0;
    flex-shrink: 0;

    & > .label {
      cursor: pointer;
      padding: 0px 5px;
      border-bottom: 1px solid black;
    }

    & > .slash {
      margin: 0px 8px;
    }
  }
`;

export default Breadcrumb;
