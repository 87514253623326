import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import Breadcrumb from '../../components/Breadcrumb';
import InfoCard from '../../components/InfoCard';
import ResourceTitleBar from '../../components/ResourceTitleBar';
import SimpleResourceItem from '../../components/SimpleResourceItem';
import BottomUpSheet from '../../components/BottomUpSheet';
import EmailTemplateEditor from './EmailTemplateEditor';
import EmailSenderEditor from './EmailSenderEditor';

function TabEmail(props) {
  const app = React.useContext(AppContext.Context);
  const [templates, setTemplates] = React.useState(null);
  const [senders, setSenders] = React.useState(null);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [isCreating, setIsCreating] = React.useState(false);
  const bottomSheetRef = React.useRef();

  React.useEffect(() => {
    async function fetchData() {
      app.actions.setLoading(true);
      try {
        setTemplates(await app.actions.fetchEmailTemplates());
        setSenders(await app.actions.fetchEmailSenders());
      } catch (ex) {
        alert('Fetch fail, please retry later');
      }
      app.actions.setLoading(false);
    }
    fetchData();
  }, [app.actions]);

  function setToInitialState() {
    setSelectedTemplate(null);
    setIsCreating(false);
  }

  async function putTemplate(record) {
    try {
      app.actions.setLoading(true);
      await app.actions.putEmailTemplate(record);
      setTemplates(await app.actions.fetchEmailTemplates());
      alert('Update success!');
    } catch (ex) {
      alert('Update fail: \n' + JSON.stringify(ex, null, 2));
    } finally {
      app.actions.setLoading(false);
      setToInitialState();
    }
  }

  async function putSender(record) {
    try {
      app.actions.setLoading(true);
      await app.actions.putEmailSender(record);
      setSenders(await app.actions.fetchEmailSenders());
      alert('Update success!');
    } catch (ex) {
      alert('Update fail: \n' + JSON.stringify(ex, null, 2));
    } finally {
      app.actions.setLoading(false);
      setToInitialState();
    }
  }

  function showEditSenderBottomSheet(sender) {
    bottomSheetRef.current.open(
      sender?.name || 'Creating New Sender',
      <EmailSenderEditor
        key={sender?.name}
        isCreating={!sender}
        sender={sender || {}}
        templates={templates}
        onSave={async (record) => {
          bottomSheetRef.current.close();
          await putSender(record);
        }}
        goBack={() => {
          bottomSheetRef.current.close();
        }}
      />,
    );
  }

  function renderBreadcrumb() {
    const navItems = ['All Resources'];

    if (selectedTemplate) {
      navItems.push(
        isCreating
          ? 'Creating New Template'
          : `[TEMPLATE] ${selectedTemplate.name}`,
      );
    }

    return (
      <Breadcrumb
        items={navItems}
        onItemClick={(_item, idx) => {
          if (idx === 0) {
            setToInitialState();
          }
        }}
      />
    );
  }

  return (
    <Wrapper>
      {renderBreadcrumb()}

      {selectedTemplate && (
        <div style={{height: 'calc(100% - 50px)', overflow: 'auto'}}>
          <EmailTemplateEditor
            template={selectedTemplate}
            onSave={putTemplate}
            goBack={setToInitialState}
            isCreating={isCreating}
          />
        </div>
      )}

      {!selectedTemplate && (
        <>
          <InfoCard>
            <ResourceTitleBar
              title="Templates"
              onCreateClick={() => {
                setIsCreating(true);
                setSelectedTemplate({});
              }}
            />

            {templates?.map((template, idx) => (
              <SimpleResourceItem
                key={idx}
                resource={template}
                idx={idx}
                onClick={() => setSelectedTemplate(template)}
              />
            ))}
          </InfoCard>

          <InfoCard style={{marginTop: 20}}>
            <ResourceTitleBar
              title="Senders"
              onCreateClick={() => showEditSenderBottomSheet()}
            />

            {senders?.map((sender, idx) => (
              <SimpleResourceItem
                key={idx}
                resource={sender}
                idx={idx}
                onClick={() => showEditSenderBottomSheet(sender)}
              />
            ))}
          </InfoCard>

          <div style={{height: 100}} />
        </>
      )}

      <BottomUpSheet getInstance={(inst) => (bottomSheetRef.current = inst)} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  overflow: audo;
`;

export default TabEmail;
