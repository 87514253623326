import React from 'react';
import styled from 'styled-components';

function InfoCard(props) {
  const {style = {}} = props;
  return <Wrapper style={style}>{props.children}</Wrapper>;
}

const Wrapper = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

export default InfoCard;
